import { useEffect, useRef, useState } from 'react';
import '@Components/Dashboards/Dashboards.css';
import jsonData from 'src/ui/datos.json';
import { NavLink, useLocation } from 'react-router-dom';
import icon_card_certified from '@Assets/icon_card_certified.png';
import icon_card_power_bi from '@Assets/icon_card_power_bi.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PowerBI from '@Components/PowerBI/PowerBI';
import PopUpInfo from '@Components/PopUpInfo/PopUpInfo';

const Dashboards = (props: { valueSearch: any; selectedOption: any }) => {
  const [data, setData] = useState(jsonData);
  const [subcarpetaName, setSubcarpetaName] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();
  // const navigate = useNavigate()
  const [emptyDashboards, setEmptyDashboards] = useState(false);
  const [showPowerBI, setShowPowerBI] = useState(false);
  const [reportID, setReportID] = useState('');
  const [datasetID, setDatasetID] = useState('');
  const [groupID, setGroupID] = useState('');
  const [breadcrumBI, setBreadcrumBI] = useState({});
  const [verificatedID, setVerificatedID] = useState(false);
  const embedContainerRef = useRef(null);
  const [expandAll, setExpandAll] = useState(false);
  const [popUpInfo, setPopUpInfo] = useState(false);

  useEffect(() => {
    const url = location.pathname.split('/').filter((segment) => segment !== '');
    const carpeta = url[0] || '';
    const subcarpeta = url[1] || '';
    const tab = url[2] || undefined;
    setShowPowerBI(false);

    const newDataFolder = jsonData.filter(
      (folder) => folder.url.toLowerCase() === carpeta.toLowerCase(),
    )[0];
    const newDataSubFolder = newDataFolder.subcarpeta.filter(
      (subfolder) => subfolder.url.toLowerCase() === subcarpeta.toLowerCase(),
    );

    setSubcarpetaName(newDataSubFolder[0].name);
    if (tab) {
      setActiveTab(tab);
      // navigate(`${newDataSubFolder[0].tabs[0].name}`)
    } else {
      if (newDataSubFolder[0].tabs) {
        setActiveTab(newDataSubFolder[0].tabs[0].name);
        // navigate(`${newDataSubFolder[0].tabs[0].name}`)
      }
    }

    const auxDataSubFolder = [
      {
        carpeta: newDataFolder.carpeta,
        url: newDataFolder.url,
        subcarpeta: newDataSubFolder,
      },
    ];

    auxDataSubFolder.forEach((carpeta) => {
      if (carpeta.subcarpeta) {
        carpeta.subcarpeta.forEach((sub) => {
          let numeroDashboards = 0;
          if (sub.dashboards) {
            numeroDashboards += sub.dashboards.length;
          }
          if (sub.tabs) {
            sub.tabs.forEach((tab) => {
              if (tab.dashboards) {
                numeroDashboards += tab.dashboards.length;
              }
            });
          }

          if (numeroDashboards > 0) {
            setEmptyDashboards(false);
          } else {
            setEmptyDashboards(true);
          }
        });
      }
    });

    setData(auxDataSubFolder);
  }, [location]);

  const handleToggle = (folderIndex, subfolderIndex, tabindex, dashboardIndex) => {
    const newData = [...data];
    if (typeof tabindex !== 'undefined') {
      const tab =
        newData[folderIndex]?.subcarpeta[subfolderIndex]?.tabs?.[tabindex]?.dashboards?.[
          dashboardIndex
        ];
      if (tab) {
        tab.opened = !tab.opened;
      }
    } else {
      const dashboard =
        newData[folderIndex]?.subcarpeta[subfolderIndex]?.dashboards?.[dashboardIndex];
      if (dashboard) {
        dashboard.opened = !dashboard.opened;
      }
    }
    setData(newData);
  };

  const handleInfoKeyPress = (event, folderIndex, subfolderIndex, tabindex, dashboardIndex) => {
    if (event.key === 'Enter') {
      handleToggle(folderIndex, subfolderIndex, tabindex, dashboardIndex);
    }
  };

  const handleTabClick = (tabname) => {
    setActiveTab(tabname);
  };

  const handleInfoTabClick = (event, tabname) => {
    if (event.key === 'Enter') {
      handleTabClick(tabname);
    }
  };

  const handleOpenPowerBI = (
    verificated,
    folder,
    subfolder,
    dashname,
    reportID,
    datasetID,
    groupID,
    owner,
    tab?,
  ) => {
    const auxReportID = reportID;
    const auxDatasetID = datasetID;
    const auxGroupID = groupID;
    const auxBreadcrum = {
      folder,
      subfolder,
      dashname,
      tab,
    };
    setBreadcrumBI(auxBreadcrum);
    setDatasetID(auxDatasetID);
    setReportID(auxReportID);
    setGroupID(auxGroupID);
    setVerificatedID(verificated);
    setShowPowerBI(!showPowerBI);
  };

  const handleOpenPowerBIKeyPress = (
    event,
    verificated,
    folder,
    subfolder,
    dashname,
    reportID,
    datasetID,
    groupID,
    owner,
    tab?,
  ) => {
    if (event.key === 'Enter') {
      handleOpenPowerBI(
        verificated,
        folder,
        subfolder,
        dashname,
        reportID,
        datasetID,
        groupID,
        owner,
        tab,
      );
    }
  };

  const handleClosePowerBI = () => {
    setPopUpInfo(false);
    setShowPowerBI(false);
  };

  const handleClosePowerBIKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClosePowerBI();
    }
  };

  const handleExpandToFullScreen = () => {
    const element = embedContainerRef.current;
    if (document.fullscreenEnabled) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    }
  };

  const handleExpandToFullScreenKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleExpandToFullScreen();
    }
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);

    const newData = [...data];
    newData.forEach((carpeta) => {
      carpeta.subcarpeta.forEach((subcarpeta) => {
        if (subcarpeta.tabs) {
          subcarpeta.tabs.forEach((tab) => {
            tab.dashboards.forEach((dashboard) => {
              dashboard.opened = true;
            });
          });
        } else {
          subcarpeta.dashboards?.forEach((dashboard) => {
            dashboard.opened = true;
          });
        }
      });
    });
    setData(newData);
  };

  const toggleExpandAllKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleExpandAll();
    }
  };

  const toggleCloseAll = () => {
    setExpandAll(!expandAll);

    const newData = [...data];
    newData.forEach((carpeta) => {
      carpeta.subcarpeta.forEach((subcarpeta) => {
        if (subcarpeta.tabs) {
          subcarpeta.tabs.forEach((tab) => {
            tab.dashboards.forEach((dashboard) => {
              dashboard.opened = false;
            });
          });
        } else {
          subcarpeta.dashboards?.forEach((dashboard) => {
            dashboard.opened = false;
          });
        }
      });
    });
    setData(newData);
  };

  const toggleCloseAllKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleCloseAll();
    }
  };

  const handlePopUpInfo = () => {
    setPopUpInfo(!popUpInfo);
  };

  const handlePopUpInfoKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePopUpInfo();
    }
  };

  return (
    <div className="dashboards">
      {showPowerBI ? (
        <>
          <div className="dashboard-buttons">
            <div
              className="info"
              role="button"
              tabIndex={0}
              onClick={() => {
                handlePopUpInfo();
              }}
              onKeyDown={(e) => {
                handlePopUpInfoKeyPress(e);
              }}
            >
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 19.5 15.5"
              >
                <path
                  id="icon_info"
                  className="cls-1"
                  d="m17.81,8.91c0,4.92-3.99,8.91-8.91,8.91S0,13.83,0,8.91,3.99,0,8.91,0s8.91,3.99,8.91,8.91Zm-1.19,0c0-4.26-3.46-7.72-7.72-7.72S1.19,4.65,1.19,8.91s3.46,7.72,7.72,7.72,7.72-3.46,7.72-7.72Zm-5.34,4.16c0,.33-.27.59-.59.59h-3.56c-.33,0-.59-.27-.59-.59s.27-.59.59-.59h1.19v-3.56h-.59c-.33,0-.59-.27-.59-.59s.27-.59.59-.59h1.19c.33,0,.59.27.59.59v4.16h1.19c.33,0,.59.27.59.59Zm-3.27-7.72c0-.49.4-.89.89-.89s.89.4.89.89-.4.89-.89.89-.89-.4-.89-.89Z"
                />
              </svg>
            </div>
            {popUpInfo && <PopUpInfo reportID={reportID} />}
            <div className="buttons">
              <div
                className="expand"
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleExpandToFullScreen();
                }}
                onKeyDown={(e) => {
                  handleExpandToFullScreenKeyPress(e);
                }}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="1 1 21.5 18.5"
                >
                  <path
                    id="svg_icon"
                    className="cls-2"
                    d="M19.54,4.06c-.09-.04-.19-.06-.29-.06h-4.5c-.41,0-.75.34-.75.75s.34.75.75.75h2.69l-4.22,4.22c-.29.29-.29.77,0,1.06,0,0,0,0,0,0,.29.29.77.29,1.06,0,0,0,0,0,0,0l4.22-4.22v2.69c0,.41.34.75.75.75.41,0,.75-.34.75-.75v-4.5c0-.1-.02-.2-.06-.29-.08-.18-.22-.33-.4-.41ZM4.46,19.94c.09.04.19.06.29.06h4.5c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-2.69l4.22-4.22c.29-.29.29-.77,0-1.06,0,0,0,0,0,0-.29-.29-.77-.29-1.06,0,0,0,0,0,0,0l-4.22,4.22v-2.69c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.5c0,.1.02.2.06.29.08.18.22.33.4.41Z"
                  />
                </svg>
              </div>
              <div
                className="close"
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleClosePowerBI();
                }}
                onKeyDown={(e) => {
                  handleClosePowerBIKeyPress(e);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          </div>
          <div className="embedContainerRef" ref={embedContainerRef}>
            <PowerBI
              breadcrum={breadcrumBI}
              verificated={verificatedID}
              reportID={reportID}
              datasetID={datasetID}
              groupID={groupID}
              close={handleClosePowerBI}
            />
          </div>
        </>
      ) : emptyDashboards ? (
        <div className="header-empty">
          <h2>Empty folder</h2>
          <div className="container">
            <div className="empty">There are no dashboards in this folder.</div>
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <h2>{subcarpetaName}</h2>
            {!expandAll ? (
              <div
                className="change-design"
                role="button"
                tabIndex={0}
                onClick={toggleExpandAll}
                onKeyDown={(e) => {
                  toggleExpandAllKeyPress(e);
                }}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 17.5 15.5"
                >
                  <path
                    d="M6-13H-6a2,2,0,0,0-2,2V-1A2,2,0,0,0-6,1H6A2,2,0,0,0,8-1V-11A2,2,0,0,0,6-13ZM-4,0H-6A1,1,0,0,1-7-1V-11a1,1,0,0,1,1-1h2ZM7-1A1,1,0,0,1,6,0H-3V-5.5H7ZM7-6.5H-3V-12H6a1,1,0,0,1,1,1Z"
                    transform="translate(8 13)"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="change-design"
                role="button"
                tabIndex={0}
                onClick={toggleCloseAll}
                onKeyDown={(e) => {
                  toggleCloseAllKeyPress(e);
                }}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 17.5 15.5"
                >
                  <path
                    d="M-3-14H-6a2,2,0,0,0-2,2v3A2,2,0,0,0-6-7h3A2,2,0,0,0-1-9v-3A2,2,0,0,0-3-14Zm1,5A1,1,0,0,1-3-8H-6A1,1,0,0,1-7-9v-3a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1ZM-3-5H-6A2,2,0,0,0-8-3V0A2,2,0,0,0-6,2h3A2,2,0,0,0-1,0V-3A2,2,0,0,0-3-5Zm1,5A1,1,0,0,1-3,1H-6A1,1,0,0,1-7,0V-3A1,1,0,0,1-6-4h3A1,1,0,0,1-2-3ZM6-14H3a2,2,0,0,0-2,2v3A2,2,0,0,0,3-7H6A2,2,0,0,0,8-9v-3A2,2,0,0,0,6-14ZM7-9A1,1,0,0,1,6-8H3A1,1,0,0,1,2-9v-3a1,1,0,0,1,1-1H6a1,1,0,0,1,1,1ZM6-5H3A2,2,0,0,0,1-3V0A2,2,0,0,0,3,2H6A2,2,0,0,0,8,0V-3A2,2,0,0,0,6-5ZM7,0A1,1,0,0,1,6,1H3A1,1,0,0,1,2,0V-3A1,1,0,0,1,3-4H6A1,1,0,0,1,7-3Z"
                    transform="translate(8 14)"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="container">
            {data?.map((carpeta, folderindex) =>
              carpeta.subcarpeta.map((subcarpeta, subindex) =>
                subcarpeta.tabs ? (
                  <>
                    <div className="tabs" key={subindex}>
                      {subcarpeta.tabs.map((tab, tabindex) => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={`tab ${activeTab === tab.name ? 'active' : ''}`}
                          key={tabindex}
                          onClick={() => {
                            handleTabClick(tab.name);
                          }}
                          onKeyDown={(e) => {
                            handleInfoTabClick(e, tab.name);
                          }}
                        >
                          <NavLink to={`/${carpeta.url}/${subcarpeta.url}/${tab.name}`}>
                            <span className="tabname">{tab.name}</span>
                            <span className="numdash">{tab.dashboards.length}</span>
                          </NavLink>
                        </div>
                      ))}
                    </div>

                    <div className="content-tab">
                      {subcarpeta.tabs.map(
                        (tab, tabindex) =>
                          activeTab === tab.name &&
                          tab.dashboards.map((dashboard, dashindex) => (
                            <div
                              className={`dashboard dash${folderindex}${subindex}${tabindex}${dashindex} ${
                                dashboard.opened ? 'grid-row' : ''
                              }`}
                              key={`dash${folderindex}${subindex}${tabindex}${dashindex}`}
                            >
                              <div
                                className={`card ${dashboard.opened ? 'new-height' : ''}`}
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                  handleOpenPowerBI(
                                    dashboard.verificated,
                                    carpeta.carpeta,
                                    subcarpeta.name,
                                    dashboard.name,
                                    dashboard.reportID,
                                    dashboard.datasetID,
                                    dashboard.groupID,
                                    dashboard.owner,
                                    tab.name,
                                  );
                                }}
                                onKeyDown={(e) => {
                                  handleOpenPowerBIKeyPress(
                                    e,
                                    dashboard.verificated,
                                    carpeta.carpeta,
                                    subcarpeta.name,
                                    dashboard.name,
                                    dashboard.reportID,
                                    dashboard.datasetID,
                                    dashboard.groupID,
                                    tab.name,
                                    dashboard.owner,
                                  );
                                }}
                              >
                                <div className="title">
                                  <img src={icon_card_power_bi} alt="graph"></img>
                                  <span>{dashboard.name}</span>
                                  {dashboard.verificated ? (
                                    <img
                                      src={icon_card_certified}
                                      alt="certified"
                                      title="Certified"
                                    ></img>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                {dashboard.opened && (
                                  <div className="breadcrum">
                                    <span>
                                      {carpeta.carpeta} {'>'} {subcarpeta.name} {'>'} {tab.name}
                                    </span>
                                  </div>
                                )}
                                {dashboard.opened && (
                                  <div className="desc">{dashboard.description}</div>
                                )}
                                <div className="tags">
                                  {dashboard.tags.slice(0, 3)?.map(
                                    (tag, index) =>
                                      tag !== '-' && (
                                        <div className="tag" key={index}>
                                          {tag}
                                        </div>
                                      ),
                                  )}
                                  {dashboard.opened && (
                                    <>
                                      {dashboard.tags.slice(3)?.map(
                                        (tag, index) =>
                                          tag !== '-' && (
                                            <div className="tag" key={index}>
                                              {tag}
                                            </div>
                                          ),
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="more-info">
                                  <div className="date-nd-visit">
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                      >
                                        <g
                                          id="icon_refresh_data"
                                          transform="translate(-364 -316)"
                                          opacity="0.6"
                                        >
                                          <g id="refresh-2" transform="translate(364 316)">
                                            <path
                                              id="Vector"
                                              d="M1.11,14.44v2.917"
                                              transform="translate(0.704 -4.85)"
                                              fill="none"
                                              stroke="#7d7d7d"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="1.2"
                                            />
                                            <path
                                              id="Vector-2"
                                              data-name="Vector"
                                              d="M11.667,5.833a5.835,5.835,0,0,1-5.833,5.833c-3.22,0-5.186-3.243-5.186-3.243H3.284M0,5.833A5.821,5.821,0,0,1,5.833,0a7.176,7.176,0,0,1,5.833,3.243m0,0V.327m0,2.917H9.077"
                                              transform="translate(1.167 1.167)"
                                              fill="none"
                                              stroke="#7d7d7d"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="1.2"
                                            />
                                            <path
                                              id="Vector-3"
                                              data-name="Vector"
                                              d="M0,0H14V14H0Z"
                                              fill="none"
                                              opacity="0"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                      <span>{dashboard.date}</span>
                                    </div>
                                    <div title="Visited">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15.178"
                                        height="15.178"
                                        viewBox="0 0 15.178 15.178"
                                      >
                                        <g
                                          id="icon_eye"
                                          transform="translate(-109.047 -189.047)"
                                          opacity="0.6"
                                        >
                                          <g id="eye" transform="translate(108 188)">
                                            <path
                                              id="Vector"
                                              d="M3.116,6.232A3.116,3.116,0,1,1,6.232,3.116,3.117,3.117,0,0,1,3.116,6.232Zm0-5.153A2.037,2.037,0,1,0,5.153,3.116,2.039,2.039,0,0,0,3.116,1.079Z"
                                              transform="translate(5.52 5.52)"
                                              fill="#7d7d7d"
                                            />
                                            <path
                                              id="Vector-2"
                                              data-name="Vector"
                                              d="M7.589,12.982C4.883,12.982,2.328,11.4.572,8.65a4.36,4.36,0,0,1,0-4.318C2.335,1.583,4.89,0,7.589,0S12.842,1.583,14.6,4.332a4.36,4.36,0,0,1,0,4.318C12.842,11.4,10.287,12.982,7.589,12.982Zm0-11.9c-2.324,0-4.548,1.4-6.1,3.836a3.27,3.27,0,0,0,0,3.152c1.554,2.44,3.778,3.836,6.1,3.836s4.548-1.4,6.1-3.836a3.27,3.27,0,0,0,0-3.152C12.137,2.476,9.913,1.079,7.589,1.079Z"
                                              transform="translate(1.047 2.145)"
                                              fill="#7d7d7d"
                                            />
                                            <path
                                              id="Vector-3"
                                              data-name="Vector"
                                              d="M0,0H15.178V15.178H0Z"
                                              transform="translate(1.047 1.047)"
                                              fill="none"
                                              opacity="0"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                      <span>{dashboard.visit}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                role="button"
                                tabIndex={0}
                                className="plus"
                                onClick={() => {
                                  handleToggle(folderindex, subindex, tabindex, dashindex);
                                }}
                                onKeyDown={(e) => {
                                  handleInfoKeyPress(e, folderindex, subindex, tabindex, dashindex);
                                }}
                              >
                                <svg
                                  className="icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18.75"
                                  height="18.75"
                                  viewBox="0 0 18.75 18.75"
                                >
                                  {dashboard.opened ? (
                                    <path d="m18.75,9.38c0,5.18-4.2,9.38-9.38,9.38S0,14.55,0,9.38,4.2,0,9.38,0s9.38,4.2,9.38,9.38Zm-1.25,0c0-4.48-3.65-8.12-8.12-8.12S1.25,4.89,1.25,9.38s3.64,8.12,8.12,8.12,8.12-3.64,8.12-8.12Zm-3.75,0c0,.35-.28.62-.62.62h-7.5c-.35,0-.62-.28-.62-.62s.28-.62.62-.62h7.5c.35,0,.62.28.62.62Z" />
                                  ) : (
                                    <path d="m18.75,9.38c0,5.18-4.2,9.38-9.38,9.38S0,14.55,0,9.38,4.2,0,9.38,0s9.38,4.2,9.38,9.38Zm-1.25,0c0-4.48-3.65-8.12-8.12-8.12S1.25,4.89,1.25,9.38s3.64,8.12,8.12,8.12,8.12-3.64,8.12-8.12Zm-3.75,0c0,.35-.28.62-.62.62h-3.12v3.12c0,.35-.28.62-.62.62s-.62-.28-.62-.62v-3.12h-3.12c-.35,0-.62-.28-.62-.62s.28-.62.62-.62h3.12v-3.12c0-.35.28-.62.62-.62s.62.28.62.62v3.12h3.12c.35,0,.62.28.62.62Z" />
                                  )}
                                </svg>
                              </div>
                            </div>
                          )),
                      )}
                    </div>
                  </>
                ) : (
                  <div className="content-tab" key="tabs">
                    {subcarpeta.dashboards?.map((dashboard, dashindex) => (
                      <div
                        className={`dashboard dash${folderindex}${subindex}${dashindex} ${
                          dashboard.opened ? 'grid-row' : ''
                        }`}
                        key={`dash${folderindex}${subindex}${dashindex}`}
                      >
                        <div
                          className={`card ${dashboard.opened ? 'new-height' : ''}`}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            handleOpenPowerBI(
                              dashboard.verificated,
                              carpeta.carpeta,
                              subcarpeta.name,
                              dashboard.name,
                              dashboard.reportID,
                              dashboard.datasetID,
                              dashboard.groupID,
                              dashboard.owner,
                            );
                          }}
                          onKeyDown={(e) => {
                            handleOpenPowerBIKeyPress(
                              e,
                              dashboard.verificated,
                              carpeta.carpeta,
                              subcarpeta.name,
                              dashboard.name,
                              dashboard.reportID,
                              dashboard.datasetID,
                              dashboard.groupID,
                              dashboard.owner,
                            );
                          }}
                        >
                          <div className="title">
                            <img src={icon_card_power_bi} alt="graph"></img>
                            <span>{dashboard.name}</span>
                            {dashboard.verificated ? (
                              <img
                                src={icon_card_certified}
                                alt="certified"
                                title="Certified"
                              ></img>
                            ) : (
                              ''
                            )}
                          </div>
                          {dashboard.opened && (
                            <div className="breadcrum">
                              <span>
                                {carpeta.carpeta} {'>'} {subcarpeta.name}
                              </span>
                            </div>
                          )}
                          {dashboard.opened && <div className="desc">{dashboard.description}</div>}
                          <div className="tags">
                            {dashboard.tags.slice(0, 3)?.map(
                              (tag, index) =>
                                tag !== '-' && (
                                  <div className="tag" key={index}>
                                    {tag}
                                  </div>
                                ),
                            )}
                            {dashboard.opened && (
                              <>
                                {dashboard.tags.slice(3)?.map(
                                  (tag, index) =>
                                    tag !== '-' && (
                                      <div className="tag" key={index}>
                                        {tag}
                                      </div>
                                    ),
                                )}
                              </>
                            )}
                          </div>
                          <div className="more-info">
                            <div className="date-nd-visit">
                              <div title="Date">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                >
                                  <g
                                    id="icon_refresh_data"
                                    transform="translate(-364 -316)"
                                    opacity="0.6"
                                  >
                                    <g id="refresh-2" transform="translate(364 316)">
                                      <path
                                        id="Vector"
                                        d="M1.11,14.44v2.917"
                                        transform="translate(0.704 -4.85)"
                                        fill="none"
                                        stroke="#7d7d7d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                      />
                                      <path
                                        id="Vector-2"
                                        data-name="Vector"
                                        d="M11.667,5.833a5.835,5.835,0,0,1-5.833,5.833c-3.22,0-5.186-3.243-5.186-3.243H3.284M0,5.833A5.821,5.821,0,0,1,5.833,0a7.176,7.176,0,0,1,5.833,3.243m0,0V.327m0,2.917H9.077"
                                        transform="translate(1.167 1.167)"
                                        fill="none"
                                        stroke="#7d7d7d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                      />
                                      <path
                                        id="Vector-3"
                                        data-name="Vector"
                                        d="M0,0H14V14H0Z"
                                        fill="none"
                                        opacity="0"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                <span>{dashboard.date}</span>
                              </div>
                              <div title="Visited">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.178"
                                  height="15.178"
                                  viewBox="0 0 15.178 15.178"
                                >
                                  <g
                                    id="icon_eye"
                                    transform="translate(-109.047 -189.047)"
                                    opacity="0.6"
                                  >
                                    <g id="eye" transform="translate(108 188)">
                                      <path
                                        id="Vector"
                                        d="M3.116,6.232A3.116,3.116,0,1,1,6.232,3.116,3.117,3.117,0,0,1,3.116,6.232Zm0-5.153A2.037,2.037,0,1,0,5.153,3.116,2.039,2.039,0,0,0,3.116,1.079Z"
                                        transform="translate(5.52 5.52)"
                                        fill="#7d7d7d"
                                      />
                                      <path
                                        id="Vector-2"
                                        data-name="Vector"
                                        d="M7.589,12.982C4.883,12.982,2.328,11.4.572,8.65a4.36,4.36,0,0,1,0-4.318C2.335,1.583,4.89,0,7.589,0S12.842,1.583,14.6,4.332a4.36,4.36,0,0,1,0,4.318C12.842,11.4,10.287,12.982,7.589,12.982Zm0-11.9c-2.324,0-4.548,1.4-6.1,3.836a3.27,3.27,0,0,0,0,3.152c1.554,2.44,3.778,3.836,6.1,3.836s4.548-1.4,6.1-3.836a3.27,3.27,0,0,0,0-3.152C12.137,2.476,9.913,1.079,7.589,1.079Z"
                                        transform="translate(1.047 2.145)"
                                        fill="#7d7d7d"
                                      />
                                      <path
                                        id="Vector-3"
                                        data-name="Vector"
                                        d="M0,0H15.178V15.178H0Z"
                                        transform="translate(1.047 1.047)"
                                        fill="none"
                                        opacity="0"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                <span>{dashboard.visit}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          className="plus"
                          onClick={() => {
                            handleToggle(folderindex, subindex, undefined, dashindex);
                          }}
                          onKeyDown={(e) => {
                            handleInfoKeyPress(e, folderindex, subindex, undefined, dashindex);
                          }}
                        >
                          <svg
                            className="icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.75"
                            height="18.75"
                            viewBox="0 0 18.75 18.75"
                          >
                            {dashboard.opened ? (
                              <path d="m18.75,9.38c0,5.18-4.2,9.38-9.38,9.38S0,14.55,0,9.38,4.2,0,9.38,0s9.38,4.2,9.38,9.38Zm-1.25,0c0-4.48-3.65-8.12-8.12-8.12S1.25,4.89,1.25,9.38s3.64,8.12,8.12,8.12,8.12-3.64,8.12-8.12Zm-3.75,0c0,.35-.28.62-.62.62h-7.5c-.35,0-.62-.28-.62-.62s.28-.62.62-.62h7.5c.35,0,.62.28.62.62Z" />
                            ) : (
                              <path d="m18.75,9.38c0,5.18-4.2,9.38-9.38,9.38S0,14.55,0,9.38,4.2,0,9.38,0s9.38,4.2,9.38,9.38Zm-1.25,0c0-4.48-3.65-8.12-8.12-8.12S1.25,4.89,1.25,9.38s3.64,8.12,8.12,8.12,8.12-3.64,8.12-8.12Zm-3.75,0c0,.35-.28.62-.62.62h-3.12v3.12c0,.35-.28.62-.62.62s-.62-.28-.62-.62v-3.12h-3.12c-.35,0-.62-.28-.62-.62s.28-.62.62-.62h3.12v-3.12c0-.35.28-.62.62-.62s.62.28.62.62v3.12h3.12c.35,0,.62.28.62.62Z" />
                            )}
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Dashboards;
