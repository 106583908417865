import { useEffect, useState } from 'react';
import '@Components/Header/Header.css';
import logo from '@Assets/logo_iberia.svg';
import { useAuth } from 'react-oidc-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import data from 'src/ui/datos.json';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = ({ onInputChange }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const userName = auth.user?.profile.fullname;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const options = data.map((item) => item.carpeta);
  options.unshift('All');

  useEffect(() => {
    onInputChange(inputValue, selectedOption);
  }, [inputValue, selectedOption, onInputChange]);

  const handleTextChange = (event: { target: { value: string } }) => {
    setInputValue(event.target.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleDivKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleDropdown();
    }
  };

  const handleSpanKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSelectedOption(event.target.innerText);
      setIsOpen(false);
    }
  };

  const handleFocus = () => {
    setIsSearchFocused(true);
  };

  const handleBlur = () => {
    setIsSearchFocused(false);
  };

  const toggleDropdownUserInfo = () => {
    setIsOpenUserInfo(!isOpenUserInfo);
  };

  const handleDropdownUserInfoKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleDropdownUserInfo();
    }
  };

  const handleClearInputClick = () => {
    setInputValue('');
    setSelectedOption('All');
  };

  const handleClearInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      setInputValue('');
      setSelectedOption('All');
    }
  };

  const handleTextKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate('/search');
    }
  };

  const handleRemoveUserKeyPress = (event) => {
    if (event.key === 'Enter') {
      void auth.removeUser();
    }
  };

  return (
    <header>
      <div className="logo">
        <NavLink to="home">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>
      <div className="search-container">
        <div className={`search ${isSearchFocused ? 'input-focused' : ''}`}>
          <div className="icon">
            <NavLink to="search">
              <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="20.62"
                height="20.62"
                viewBox="0 0 20.62 20.62"
              >
                <g transform="translate(-472 -16)">
                  <path
                    d="M17.835,1.29,12.862-3.683a7.278,7.278,0,0,0,1.763-4.754A7.312,7.312,0,0,0,7.312-15.75,7.312,7.312,0,0,0,0-8.437,7.312,7.312,0,0,0,7.312-1.125a7.279,7.279,0,0,0,4.754-1.763L17.04,2.085a.562.562,0,0,0,.8-.8ZM7.312-2.25A6.194,6.194,0,0,1,1.125-8.437a6.194,6.194,0,0,1,6.187-6.187A6.194,6.194,0,0,1,13.5-8.437,6.194,6.194,0,0,1,7.312-2.25Z"
                    transform="translate(475 35)"
                    fill="#333"
                  />
                  <rect width="24" height="24" transform="translate(472 16)" fill="none" />
                </g>
              </svg>
            </NavLink>
          </div>
          <input
            type="text"
            value={inputValue}
            onChange={handleTextChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleTextKeyPress}
          />
          <label htmlFor="carpets">in</label>
          <div className="dropdown-container">
            <div
              role="button"
              tabIndex={0}
              className={`dropdown-header ${isOpen ? 'active' : ''}`}
              onClick={toggleDropdown}
              onKeyDown={handleDivKeyPress}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              {selectedOption ?? 'All'}

              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18.75"
                height="18.75"
                viewBox="0 0 18.75 18.75"
              >
                {isOpen ? (
                  <g id="icon_chevron_up" transform="translate(56 112) rotate(180)">
                    <path
                      id="Trazado_3781"
                      data-name="Trazado 3781"
                      d="M4.889-5.594a.561.561,0,0,0,.017-.795.56.56,0,0,0-.795-.017L0-2.467-4.111-6.405a.56.56,0,0,0-.795.017.561.561,0,0,0,.017.795l4.5,4.312A.558.558,0,0,0,0-1.125a.558.558,0,0,0,.389-.157Z"
                      transform="translate(44 105)"
                      fill="#333"
                    />
                    <rect
                      id="Rectángulo_1718"
                      data-name="Rectángulo 1718"
                      width="24"
                      height="24"
                      transform="translate(32 88)"
                      fill="none"
                    />
                  </g>
                ) : (
                  <g id="icon_search_chevron_down" transform="translate(-32 -88)">
                    <path
                      id="Trazado_3780"
                      data-name="Trazado 3780"
                      d="M4.889-5.594a.561.561,0,0,0,.017-.795.56.56,0,0,0-.795-.017L0-2.467-4.111-6.405a.56.56,0,0,0-.795.017.561.561,0,0,0,.017.795l4.5,4.312A.558.558,0,0,0,0-1.125a.558.558,0,0,0,.389-.157Z"
                      transform="translate(44 105)"
                      fill="#333"
                    />
                    <rect
                      id="Rectángulo_1718"
                      data-name="Rectángulo 1718"
                      width="24"
                      height="24"
                      transform="translate(32 88)"
                      fill="none"
                    />
                  </g>
                )}
              </svg>
            </div>
            {isOpen && (
              <div className="dropdown-options">
                {options.map((option, index) => (
                  <span
                    role="button"
                    tabIndex={0}
                    key={index}
                    onClick={() => {
                      handleOptionClick(option);
                    }}
                    onKeyDown={(e) => {
                      handleSpanKeyPress(e);
                    }}
                  >
                    {option}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="clear-input">
          {(inputValue || selectedOption !== 'All') && (
            <div
              role="button"
              tabIndex={0}
              onClick={handleClearInputClick}
              onKeyDown={(e) => {
                handleClearInputKeyPress(e);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
              <span>Clear Search</span>
            </div>
          )}
        </div>
      </div>
      <div
        className="user-info"
        role="button"
        tabIndex={0}
        onClick={toggleDropdownUserInfo}
        onKeyDown={(e) => {
          handleDropdownUserInfoKeyPress(e);
        }}
      >
        <svg
          className="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 20.62 20.62"
        >
          <path d="m20.62,10.31c0,5.7-4.62,10.31-10.31,10.31S0,16.01,0,10.31,4.62,0,10.31,0s10.31,4.62,10.31,10.31Zm-1.38,0c0-4.93-4.01-8.94-8.94-8.94S1.38,5.38,1.38,10.31c0,2.2.8,4.21,2.12,5.77,1-1.8,2.9-3.02,5.1-3.02h3.42c2.2,0,4.11,1.22,5.1,3.02,1.32-1.56,2.12-3.57,2.12-5.77Zm-3.14,6.79c-.7-1.57-2.27-2.66-4.09-2.66h-3.42c-1.82,0-3.39,1.1-4.09,2.66,1.56,1.34,3.59,2.15,5.8,2.15s4.24-.81,5.8-2.15Zm-2.36-8.85c0,1.9-1.54,3.44-3.44,3.44s-3.44-1.54-3.44-3.44,1.54-3.44,3.44-3.44,3.44,1.54,3.44,3.44Zm-1.38,0c0-1.14-.92-2.06-2.06-2.06s-2.06.92-2.06,2.06.93,2.06,2.06,2.06,2.06-.92,2.06-2.06Z" />
        </svg>
      </div>
      {isOpenUserInfo && (
        <div className="dropdown-user-info">
          <div
            className="logout"
            role="button"
            tabIndex={0}
            onClick={() => auth.removeUser()}
            onKeyDown={(e) => {
              handleRemoveUserKeyPress(e);
            }}
          >
            Sign Out
          </div>
          <div className="user-name">{userName}</div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default Header;
