import { useEffect, useState } from 'react';
import '@Components/PowerBI/PowerBI.css';
import axios from 'axios';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import icon_card_certified from '@Assets/icon_card_certified.png';
import Breadcrum from '@Components/Breadcrum/Breadcrum';
import { PageNavigationPosition } from 'powerbi-models';

// const clientId = '2a832b6c-c8df-488f-90f1-334bcc1c3d08';
// const tenantId = '188b450b-7545-493f-9096-854ed4977730';
// const clientSecret = 'VAW8Q~T0~mB9iPtT3NAXrEod.2eie0H5W0os5a9_';
// const groupID = '35f1e437-6882-4b34-9072-84e2bb91192b';

const PowerBI = (props: { breadcrum; reportID; datasetID; groupID; verificated }) => {
  const [embededToken, setEmbededToken] = useState('');
  const [embededUrl, setEmbededUrl] = useState('');

  useEffect(() => {
    axios
      .get('https://21u3md2v2e.execute-api.us-east-1.amazonaws.com/api/token', {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        axios
          .post(
            'https://api.powerbi.com/v1.0/myorg/GenerateToken',
            {
              reports: [
                {
                  id: props.reportID,
                },
              ],
              lifetimeInMinutes: 10,
              datasets: [
                {
                  id: props.datasetID,
                },
              ],
            },
            {
              headers: { Authorization: `Bearer ${result?.data.access_token}` },
            },
          )
          .then((result1) => {
            setEmbededToken(result1.data.token);

            axios
              .get(
                `https://api.powerbi.com/v1.0/myorg/groups/${props.groupID}/reports/${props.reportID}`,
                {
                  headers: { Authorization: `Bearer ${result?.data.access_token}` },
                },
              )
              .then((result2) => {
                setEmbededUrl(result2.data.embedUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.reportID, props.datasetID, props.groupID]);
  return (
    <>
      <div className="title-bi">
        <Breadcrum
          folder={props.breadcrum.folder}
          subfolder={props.breadcrum.subfolder}
          dashname={props.breadcrum.dashname}
          tab={props.breadcrum.tab}
        ></Breadcrum>
        {props.verificated && (
          <img src={icon_card_certified} alt="certified" title="Certified"></img>
        )}
      </div>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: props.reportID,
          embedUrl: embededUrl,
          accessToken: embededToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
              pageNavigation: {
                visible: true,
                position: PageNavigationPosition.Bottom,
              },
            },
            bars: {
              actionBar: {
                visible: true,
              },
            },
            background: models.BackgroundType.Default,
          },
        }}
        cssClassName={'reportClass'}
      />
    </>
  );
};

export default PowerBI;
