import '@Components/Breadcrum/Breadcrum.css';

const Breadcrum = (props: { folder; subfolder; dashname; tab? }) => {
  return (
    <div className="breadcrum">
      <h2>
        {props.folder} {' > '} <a href={`/${props.folder}/${props.subfolder}`}>{props.subfolder}</a>
        {props.tab ? (
          <>
            {' > '} <a href={`/${props.folder}/${props.subfolder}/${props.tab}`}> {props.tab}</a>
          </>
        ) : (
          ''
        )}
        {' : '} {props.dashname}
      </h2>
    </div>
  );
};

export default Breadcrum;
