import PropTypes from 'prop-types';

const TextHighlighter = ({ text, search }) => {
  const highlightText = (text, search) => {
    if (!search || search === '') {
      return text;
    }
    const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearch})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => (regex.test(part) ? <mark key={index}>{part}</mark> : part));
  };

  return <span>{highlightText(text, search)}</span>;
};

TextHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

export default TextHighlighter;
