import { useState } from 'react';
import '@Components/Sidebar/Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import jsonData from 'src/ui/datos.json';
import { NavLink } from 'react-router-dom';
import icon_menu_folder from '@Assets/icon_menu_folder.svg';
import icon_menu_home from '@Assets/icon_menu_home.svg';

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (index: any) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleMenuKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      handleMenuClick(index);
    }
  };

  return (
    <div className="left-sidebar">
      <div className="home-option">
        <NavLink to="home">
          <img src={icon_menu_home} alt="icon-menu"></img>
          <span>Home</span>
        </NavLink>
      </div>
      {jsonData?.map((folder: any, index: any) => (
        <div key={index}>
          <div
            role="button"
            tabIndex={0}
            className="folder"
            onClick={() => {
              handleMenuClick(index);
            }}
            onKeyDown={(e) => {
              handleMenuKeyPress(e, index);
            }}
          >
            <FontAwesomeIcon
              className="arrow"
              icon={activeMenu === index ? faChevronDown : faChevronRight}
            />
            <img src={icon_menu_folder} alt="folder"></img>
            <span>{folder.carpeta}</span>
          </div>
          {activeMenu === index && (
            <div className="subfolder">
              {folder.subcarpeta.map(
                (subfolder: any, subIndex: any) =>
                  subfolder.name !== '-' && (
                    <div key={subIndex}>
                      <NavLink to={`${folder.url}/${subfolder.url}`}>
                        <img src={icon_menu_folder} alt="subfolder"></img>
                        <span>{subfolder.name}</span>
                      </NavLink>
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
