import '@Ui/app.css';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import Header from '@Components/Header/Header';
import Home from '@Components/Home/Home';
import Sidebar from '@Components/Sidebar/Sidebar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboards from '@Components/Dashboards/Dashboards';
import Search from '@Components/Search/Search';

const App = () => {
  const auth = useAuth();
  /* const validUsers: string[] = import.meta.env.VITE_PF_VALID_USERS
    ? JSON.parse(import.meta.env.VITE_PF_VALID_USERS)
    : []; */
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('All');
  // const valid = validUsers.find((email) => email === auth.user?.profile.email);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (!auth.isAuthenticated) {
    return <></>;
  }

  const handleInputChange = (inputValue, selectedOption) => {
    setInputValue(inputValue);
    setSelectedOption(selectedOption);
  };

  return (
    <div className="app_component">
      <Header onInputChange={handleInputChange} />
      <div className="container">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/login" element={<Navigate to="/home" />} />
          <Route index path="home" element={<Home />} />
          <Route
            path=":carpeta/:subcarpeta/:tab?"
            element={<Dashboards inputValue={inputValue} selectedOption={selectedOption} />}
          />
          <Route
            path="search"
            element={<Search inputValue={inputValue} selectedOption={selectedOption} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
