import '@Components/PopUpInfo/PopUpInfo.css';
import jsonData from 'src/ui/datos.json';
import { useEffect, useState } from 'react';

const PopUpInfo = (props: { reportID: any }) => {
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    if (props.reportID) {
      const newData = [...jsonData];
      newData.forEach((folder) => {
        folder.subcarpeta.forEach((subfolder) => {
          if (subfolder.tabs) {
            subfolder.tabs.forEach((tab) => {
              tab.dashboards.forEach((dash) => {
                if (props.reportID === dash.reportID) {
                  setDashboard(dash);
                }
              });
            });
          } else {
            subfolder.dashboards?.forEach((dash) => {
              if (props.reportID === dash.reportID) {
                setDashboard(dash);
              }
            });
          }
        });
      });
    }
  }, [props.reportID]);

  return (
    <>
      <div className="triangle"></div>
      <div className="popUpInfo">
        {dashboard.name ? (
          <>
            <p>{dashboard.name}</p>
            <p>{dashboard.description}</p>
            <div className="tags">
              {dashboard.tags?.map(
                (tag, index) =>
                  tag !== '-' && (
                    <div className="tag" key={index}>
                      {tag}
                    </div>
                  ),
              )}
            </div>
            <div className="more-info">
              <div className="date-nd-visit">
                <div title="Date">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g id="icon_refresh_data" transform="translate(-364 -316)" opacity="0.6">
                      <g id="refresh-2" transform="translate(364 316)">
                        <path
                          id="Vector"
                          d="M1.11,14.44v2.917"
                          transform="translate(0.704 -4.85)"
                          fill="none"
                          stroke="#7d7d7d"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.2"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M11.667,5.833a5.835,5.835,0,0,1-5.833,5.833c-3.22,0-5.186-3.243-5.186-3.243H3.284M0,5.833A5.821,5.821,0,0,1,5.833,0a7.176,7.176,0,0,1,5.833,3.243m0,0V.327m0,2.917H9.077"
                          transform="translate(1.167 1.167)"
                          fill="none"
                          stroke="#7d7d7d"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.2"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H14V14H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>{dashboard.date}</span>
                </div>
                <div title="Visited">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.178"
                    height="15.178"
                    viewBox="0 0 15.178 15.178"
                  >
                    <g id="icon_eye" transform="translate(-109.047 -189.047)" opacity="0.6">
                      <g id="eye" transform="translate(108 188)">
                        <path
                          id="Vector"
                          d="M3.116,6.232A3.116,3.116,0,1,1,6.232,3.116,3.117,3.117,0,0,1,3.116,6.232Zm0-5.153A2.037,2.037,0,1,0,5.153,3.116,2.039,2.039,0,0,0,3.116,1.079Z"
                          transform="translate(5.52 5.52)"
                          fill="#7d7d7d"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.589,12.982C4.883,12.982,2.328,11.4.572,8.65a4.36,4.36,0,0,1,0-4.318C2.335,1.583,4.89,0,7.589,0S12.842,1.583,14.6,4.332a4.36,4.36,0,0,1,0,4.318C12.842,11.4,10.287,12.982,7.589,12.982Zm0-11.9c-2.324,0-4.548,1.4-6.1,3.836a3.27,3.27,0,0,0,0,3.152c1.554,2.44,3.778,3.836,6.1,3.836s4.548-1.4,6.1-3.836a3.27,3.27,0,0,0,0-3.152C12.137,2.476,9.913,1.079,7.589,1.079Z"
                          transform="translate(1.047 2.145)"
                          fill="#7d7d7d"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H15.178V15.178H0Z"
                          transform="translate(1.047 1.047)"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>{dashboard.visit}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <p>No info for this dashboard</p>
          </>
        )}
      </div>
    </>
  );
};

export default PopUpInfo;
